<template>
  <div id="map"></div>
</template>

<script>
import gmapsInit from "@/utils/gmaps.js";

export default {
  name: "GoogleMap",
  props: {
    center: Object,
    currentLocation: Object,
    markers: Array,
    directions: [Object, null]
  },
  data() {
    return {
      google: null,
      map: null,
      renderedMarkers: [],
      locationMarker: null,
      directionsService: null,
      directionsRenderer: null
    };
  },
  methods: {
    clearAllMarkers() {
      this.renderedMarkers.map(marker => {
        marker.setMap(null);
      });

      this.renderedMarkers = [];
    },
    fitMarkerBounds() {
      var bounds = new this.google.maps.LatLngBounds();
      for (var i = 0; i < this.renderedMarkers.length; i++) {
        bounds.extend(this.renderedMarkers[i].getPosition());
      }

      this.map.fitBounds(bounds);
    }
  },
  async mounted() {
    try {
      var options = {
        center: this.center,
        zoom: 13,
        mapId: process.env.VUE_APP_GMAPS_MAP_IDS,
        gestureHandling: "greedy",
        disableDefaultUI: true
      };
      this.google = await gmapsInit();
      this.map = new this.google.maps.Map(this.$el, options);
      this.directionsService = new this.google.maps.DirectionsService();
      this.directionsRenderer = new this.google.maps.DirectionsRenderer();
      this.directionsRenderer.setMap(this.map);
    } catch (error) {
      console.error(error);
    }
  },
  watch: {
    markers: function() {
      console.log(this.markers);
      const map = this.map;
      this.clearAllMarkers();
      this.renderedMarkers = this.markers.map(marker => {
        const renderedMarker = new this.google.maps.Marker({
          position: { lat: marker.latlng.x_, lng: marker.latlng.N_ },
          map: map,
          animation: this.google.maps.Animation.DROP,
          title: marker.title
        });

        renderedMarker.addListener("click", () => {
          this.$emit("marker-click", { marker, renderedMarker });
        });

        return renderedMarker;
      });

      this.fitMarkerBounds();
    },

    center: function(newValue, oldValue) {
      if (this.map === null) {
        console.log("map not created yet");
        return;
      }

      if (oldValue.lat !== 0 || oldValue.lng !== 0) {
        console.log("center already set");
        return;
      }

      this.map.setCenter(newValue);
    },
    currentLocation: function(newValue) {
      if (this.map === null || this.google === null) {
        return;
      }
      if (this.locationMarker === null) {
        const map = this.map;
        this.locationMarker = new this.google.maps.Marker({
          position: { lat: newValue.lat, lng: newValue.lng },
          map: map,
          icon: "/img/dot.png"
        });
      }

      this.locationMarker.setPosition({ lat: newValue.lat, lng: newValue.lng });
    },
    directions: function(newValue) {
      const renderer = this.directionsRenderer;
      if (newValue === null) {
        renderer.set("directions", null);
        return;
      }

      this.directionsService.route(
        {
          origin: newValue.from,
          destination: newValue.to,
          travelMode: "WALKING"
        },
        function(response, status) {
          if (status === "OK") {
            renderer.setDirections(response);
          } else {
            console.log("Directions request failed due to " + status);
          }
        }
      );
    }
  }
};
</script>
<style>
html,
body {
  margin: 0;
  padding: 0;
}

#map {
  width: 100%;
  height: 100vh;
}
</style>
