import firebase from "firebase/app";
import "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCBESTRqen1cK0X40dMBhC5VPx1NldLLrg",
  authDomain: "travel-app-28b42.firebaseapp.com",
  projectId: "travel-app-28b42",
  storageBucket: "travel-app-28b42.appspot.com",
  messagingSenderId: "604077914769",
  appId: "1:604077914769:web:99897c62c6a3420223dd30"
};

// Get a Firestore instance
export const db = firebase.initializeApp(firebaseConfig).firestore();

// Export types that exists in Firestore
// This is not always necessary, but it's used in other examples
const { Timestamp, GeoPoint } = firebase.firestore;
export { Timestamp, GeoPoint };

// if using Firebase JS SDK < 5.8.0
db.settings({ timestampsInSnapshots: true });
