<template>
  <div>
    <google-map
      :markers="markers"
      :center="currentLocation"
      :currentLocation="currentLocation"
      :directions="directions"
      @marker-click="handleMarkerClick"
    />
    <transition name="fade">
      <poi-info-window
        v-if="selectedMarker"
        :marker-info="selectedMarker.marker"
        :marker="selectedMarker.renderedMarker"
        @close-poi-info-modal="handleClosePoiInfoWindow"
        @directions="handleDirections"
      ></poi-info-window>
    </transition>
    <button
      v-if="directions"
      @click="directions = null"
      class="cancel-directions"
    >
      Cancel trip
    </button>
  </div>
</template>

<script>
import GoogleMap from "../components/GoogleMap.vue";
import PoiInfoWindow from "../components/PoiInfoWindow.vue";
import { db } from "../utils/firebase";

export default {
  name: "Map",
  components: { GoogleMap, PoiInfoWindow },
  data() {
    return {
      currentLocation: { lat: 0, lng: 0 },
      markers: [],
      selectedMarker: null,
      directions: null
    };
  },
  methods: {
    watchGeo() {
      if (!navigator.geolocation) {
        console.log("please enable location services");
        this.updateCurrentGeolocation(0, 0);
        return;
      }

      const geolocationOptions = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0
      };

      navigator.geolocation.watchPosition(
        position => {
          console.log(position);
          this.updateCurrentGeolocation(
            position.coords.latitude,
            position.coords.longitude
          );
        },
        err => {
          console.log(
            `error occured fetching current position(${err.code}): ${err.message}`
          );
          const cachedLocation = this.currentLocation;
          this.updateCurrentGeolocation(
            cachedLocation.lat || 0,
            cachedLocation.lng || 0
          );
        },
        geolocationOptions
      );
    },
    updateCurrentGeolocation(lat, lng) {
      this.currentLocation = { lat, lng };
      console.log({ currentLocation: this.currentLocation });
    },
    handleMarkerClick(marker) {
      this.selectedMarker = marker;
    },
    handleClosePoiInfoWindow() {
      this.selectedMarker = null;
    },
    handleDirections(to) {
      this.directions = {
        from: this.currentLocation,
        to: to
      };
    }
  },
  firestore() {
    return {
      markers: db.collection("points_of_interest")
    };
  },
  mounted() {
    this.watchGeo();
  }
};
</script>
<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.25s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.cancel-directions {
  position: absolute;
  bottom: 5%;
  right: 5%;
  z-index: 10;
}
</style>
