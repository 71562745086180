<template>
  <div>
    <div class="backdrop"></div>
    <transition name="test">
      <div v-if="open" class="content">
        <div class="content-text">
          <h3>{{ markerInfo.title }}</h3>
          <p>
            {{ markerInfo.description }}
          </p>
        </div>
        <a href="#" @click.prevent="close" class="close">Go back</a>
        <a href="#" @click.prevent="directions" class="directions">Let's go</a>
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  name: "PoiInfoWindow",
  props: {
    markerInfo: Object,
    marker: Object
  },
  data() {
    return {
      open: false
    };
  },
  methods: {
    close() {
      this.open = false;
      setTimeout(() => this.$emit("close-poi-info-modal"), 200);
    },
    directions() {
      this.$emit("directions", this.marker.getPosition());
      this.close();
    }
  },
  created() {
    document.addEventListener("backbutton", this.close, false);
    const unregisterRouterGuard = this.$router.beforeEach((to, from, next) => {
      this.close();

      next(false);
    });

    this.$once("hook:destroyed", () => {
      unregisterRouterGuard();
    });
  },
  beforeDestroy() {
    document.removeEventListener("backbutton", this.close);
  },
  mounted() {
    this.open = true;
  }
};
</script>
<style scoped>
.backdrop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.25);
  z-index: 100;
}
.content {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 95%;
  min-height: 95%;
  background: white;
  z-index: 100;
  margin: auto;
  border-top-left-radius: 3%;
  border-top-right-radius: 3%;
}
.close {
  position: absolute;
  bottom: 5%;
  left: 5%;
}
.directions {
  position: absolute;
  bottom: 5%;
  right: 5%;
}
.content-text {
  padding: 5%;
}
.test-enter-active,
.test-leave-active {
  transition: all 0.25s;
}
.test-enter, .test-leave-to /* .fade-leave-active below version 2.1.8 */ {
  height: 0;
  min-height: 0;
  top: 100%;
}
</style>
